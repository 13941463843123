import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A selection of tools to help product designers design accessibly.`}</p>
    <h3>{`On testing color contrast`}</h3>
    <p>{`When testing contrast be wary of tools that use color pickers. The picked color may differ depending on your color space settings and other factors. Always prefer direct value input.`}</p>
    <h2>{`Figma`}</h2>
    <h3>{`Annotation Toolkit (Internal)`}</h3>
    <p>{`This Figma library includes annotations to use on your designs prior to developer hand off. These annotations let you document semantic structure, make visuals and interactions accessible
Annotate design system components, outline DOM order, and work better together. It is active by default in all new Figma files, and can also be activated `}<a parentName="p" {...{
        "href": "https://accessibility-playbook.github.com/enabling-a11y-design-toolkit-library-in-figma"
      }}>{`manually`}</a>{` if needed.`}</p>
    <p><img parentName="p" {...{
        "src": "https://github.com/user-attachments/assets/4816f0d0-0a00-40d6-bc69-c1d988b82092",
        "alt": "GitHub Annotation Toolkit cover image, featuring an annotated GitHub.com homepage and a design checkpoints tool overlaying some abstract branded GitHub-y key art. A badge in the top right corner says Beta."
      }}></img></p>
    <h3><a parentName="h3" {...{
        "href": "https://www.figma.com/community/plugin/731310036968334777/A11y---Focus-Orderer"
      }}>{`A11y - Focus Order plugin`}</a></h3>
    <p>{`Select your elements in the desired tabbing order, add them to the plugin to get a visible layer label, and use the tab key to test the tab order from inside of Figma.`}</p>
    <p><strong parentName="p">{`How does this help?`}</strong>{` Instead of manually placing tab order stickers on your design, let the plugin do it for you, and you can tab through your design to see if the flow makes sense.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/40274682/102416403-9dd87480-3fae-11eb-98c3-1b85046d98b7.png",
        "alt": "\"\""
      }}></img></p>
    <h3><a parentName="h3" {...{
        "href": "https://www.figma.com/community/plugin/748533339900865323/Contrast"
      }}>{`Contrast plugin`}</a></h3>
    <p>{`This plugin will check the contrast of text, graphical objects, and even gradients.`}</p>
    <p><strong parentName="p">{`How does this help?`}</strong>{` Checking the color contrast is one of the most important steps to take as a designer. Now you can test contrast without leaving the design tool.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/40274682/88444681-1c036700-cdd3-11ea-9be2-92eb651419d3.png",
        "alt": "\"\""
      }}></img></p>
    <h3><a parentName="h3" {...{
        "href": "https://www.figma.com/community/plugin/733343906244951586/Color-Blind"
      }}>{`Color Blind plugin`}</a></h3>
    <p>{`Color Blind allows you to view your designs in the eight different types of color vision deficiencies.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/694831/103639152-3890f700-4f4e-11eb-9e7f-1fcd9582ad68.png",
        "alt": "\"\""
      }}></img></p>
    <h2>{`System tools`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://apps.apple.com/us/app/sim-daltonism/id693112260?mt=12"
      }}>{`Sim Daltonism (MacOS)`}</a></h3>
    <p>{`Free application in the Mac App Store that can simulate various color blindness disorders.`}</p>
    <p><strong parentName="p">{`How does this help?`}</strong>{` When you're designing something that uses color (yes, that's everything), you can use this app to see the design as someone who has color blindness would see it, and check if it still makes sense.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/40274682/88444732-5ff66c00-cdd3-11ea-97f6-350c2f5a6f32.png",
        "alt": "\"\""
      }}></img></p>
    <h3><a parentName="h3" {...{
        "href": "http://www.coloursimulations.com/"
      }}>{`Colour Simulations (Windows)`}</a></h3>
    <p>{`Free application for Windows that is comparable to Sim Daltonism on Mac.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/40274682/103688232-5ce8d580-4f46-11eb-9b63-7a4ff7936517.png",
        "alt": "\"\""
      }}></img></p>
    <h2>{`Web tools`}</h2>
    <h3><a parentName="h3" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM Contrast Checker`}</a></h3>
    <p>{`The WebAIM contrast checker is the golden standard and does a good job explaining what the result means.
At GitHub the WebAIM contrast checker is the standard that has to be met.`}</p>
    <p>{`There is also a `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/linkcontrastchecker/?fcolor=D9D9D9&bcolor=FFFFFF"
      }}>{`link contrast checker`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/813754/188844756-2340bcbd-2630-4a5f-be4a-8796774601cc.png",
        "alt": "\"webaim contrast color checker website\""
      }}></img></p>
    <h3><a parentName="h3" {...{
        "href": "https://color.review/"
      }}>{`Color Review`}</a></h3>
    <p>{`Color review is fantastic if you're looking for help finding an accessible color in an existing palette.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/40274682/88444756-90d6a100-cdd3-11ea-876c-1c850ae19223.png",
        "alt": "\"\""
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      